<template>
  <v-card>
    <v-toolbar
      flat
    >
      <v-btn
        class="d-sm-none"
        icon
        @click="show = !show"
      >
        <v-icon>
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
      <v-fade-transition>
        <div
          v-if="itemCount"
          class="text-caption"
        >
          {{ itemCount === 1 ? '1 resultat' : `${itemCount} resultater` }}
        </div>
      </v-fade-transition>
      <v-spacer />
      <div
        class="d-flex align-center"
        style="gap: .5rem;"
      >
        <v-btn
          small
          text
          @click="$emit('click:reset')"
        >
          <v-icon>
            mdi-filter-remove-outline
          </v-icon>
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          small
          @click="search"
        >
          Søg
        </v-btn>
      </div>
    </v-toolbar>
    <v-expand-transition>
      <v-card-text
        v-show="show"
      >
        <slot></slot>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'ListFilters',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    itemCount: {
      type: Number | null,
      default: null
    }
  },
  data() {
    return {
      show: !this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    search () {
      if (this.$vuetify.breakpoint.mobile) {
        this.show = false;
      }

      this.$emit('click:search');
    }
  }
}
</script>