<template>
  <v-btn
    color="primary"
    :loading="loading"
    large
    @click="$emit('click')"
  >
    <span class="d-none d-sm-block">
      Søg
    </span>
    <v-icon
      :right="!$vuetify.breakpoint.mobile"
    >
      mdi-keyboard-return
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ListSearchButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>