<template>
  <CaseCard
    color="blue-grey lighten-3"
    icon="mdi-hammer-screwdriver"
    :item="item"
    :statuses="metadata.statuses"
    :to="to"
    :types="metadata.types"
    :departments="metadata.departments"
    :subject-areas="metadata.subjectAreas"
    :active-types="[ item.typeId ]"
    :active-departments="item.departments.map(d => d.value)"
    :active-subject-areas="item.subjectAreas.map(s => s.value)"
    :hide-status="hideStatus"
  >
    <template #body>
      <div
        v-if="item.focusPoints"
        class="focusPoints"
      >
        <h5>{{ $t('deliveries.card.focusPoints') }}</h5>
        <ul>
          <li
            v-for="(fp, index) in item.focusPoints"
            :key="index"
          >
            {{ fp }}
          </li>
        </ul>
      </div>
    </template>
  </CaseCard>
</template>

<script>
import CaseCard from '@/components/CaseCard'

export default {
  name: 'DeliveryCard',
  components: {
    CaseCard
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    to: String,
    hideStatus: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
