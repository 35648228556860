<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <!-- <v-btn :dark="darkbtn" :outlined="!darkbtn" small fab color="primary" v-bind="attrs" v-on="on" @click="init">
      <v-icon dark>mdi-plus</v-icon> 
    </v-btn> -->
      <span v-if="listButton">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="d-none d-sm-block"
          text
          @click="init"
        >
          Ny leverance
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </span>
      <span v-else>
        <v-btn
          plain
          small
          v-bind="attrs"
          v-on="on"
          @click="init"
        >
          <v-icon left>mdi-plus</v-icon>
          Ny leverance
        </v-btn>
      </span>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Her kan du oprette en leverance
      </v-card-title>

      <Loading :loading="loading" />

      <v-card-text v-if="!loading && model">

        <v-container fluid v-if="!createResult">
          <v-row>
            <v-col>
              <v-form v-model="valid">
                <v-autocomplete
                  v-model="model.data.deliveryType"
                  :rules="rules.required"
                  :items="model.metadata.deliveryTypes"
                  item-text="text"
                  item-value="value"
                  label="Type"
                  return-object
                  :search-input.sync="deliveryInput"
                  auto-select-first
                  small-chips
outlined dense @input="deliveryInput = null"
                />
                <v-autocomplete
                  v-model="model.data.location"
                  :rules="rules.required"
                  :items="model.metadata.locations"
                  item-text="text"
                  item-value="value"
                  label="Lokation"
                  return-object
                  :search-input.sync="locationInput"
                  :disabled="!!taskRequest"
                  auto-select-first
small-chips outlined dense @input="locationInput = null"
                />
                <v-autocomplete
                  v-model="model.data.departments"
                  :rules="rules.required"
                  :items="model.metadata.departments"
                  item-text="text"
                  item-value="value"
                  label="Enheder"
                  return-object
                  :search-input.sync="departmentInput"
                  :disabled="!!taskRequest"
                  auto-select-first
small-chips deletable-chips multiple outlined dense @input="departmentInput = null"
                />
                <v-autocomplete
                  v-model="model.data.subjectAreas"
                  :rules="rules.required"
                  :items="model.metadata.subjectAreas"
                  item-text="text"
                  item-value="value"
                  label="Tekniske områder"
                  return-object
                  :search-input.sync="subjectInput"
                  :disabled="!!taskRequest"
                  auto-select-first
small-chips deletable-chips multiple outlined dense @input="subjectInput = null"
                />
                <v-autocomplete
                  v-for="r in model.data.responsibles"
                  :key="r.responsibilityTitle"
                  v-model="r.selectedPersonId"
                  :rules="rules.required"
                  :items="r.selectablePersons"
                  :label="r.responsibilityTitle"
                  outlined
                  dense
                />
                <v-autocomplete
                  v-model="model.data.followerPersons"
                  :items="model.metadata.persons"
                  item-text="text"
                  item-value="value"
                  label="Følgere"
                  return-object
                  :search-input.sync="followerPersonsInput"
                  auto-select-first
                  small-chips
                  outlined
dense multiple @input="followerPersonsInput = null"
                />
                <v-text-field
                  v-model="model.data.title"
                  :rules="rules.required"
                  maxlength="250"
                  label="Titel"
                  :disabled="!!taskRequest"
                  outlined
                  dense
                />
                <v-textarea
                  v-model="model.data.description"
                  label="Beskrivelse"
                  outlined
                  dense
                />
                <v-menu
                  v-model="menuExpectedStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="model.data.expectedStart"
                      :rules="rules.required"
                      label="Forventet udførsel start"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="model.data.expectedStart"
                    :max="model.data.expectedEnd"
                    @input="menuExpectedStart = false"
                  />
                </v-menu>
                <v-menu
                  v-model="menuExpectedEnd"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="model.data.expectedEnd"
                      :rules="rules.required"
                      label="Forventet udførsel slut"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="model.data.expectedEnd"
                    :min="model.data.expectedStart"
                    @input="menuExpectedEnd = false"
                  />
                </v-menu>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="!!createResult">
          <div>Din leverance blev oprettet med følgende nummer:</div>
          <div>
            <router-link :to="`/delivery/${createResult.model.id}`">
              {{ createResult.model.code }}
            </router-link>
          </div>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!createResult"
          outlined
          color="primary"
          @click="cancel"
        >
          Annuller
        </v-btn>
        <v-btn
          v-if="!createResult"
          color="secondary"
          :loading="saving"
          :disabled="!valid"
          @click="create"
        >
          Opret leverance
        </v-btn>
        <v-btn
          v-if="!!createResult"
          outlined
          color="primary"
          @click="close"
        >
          Luk
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'DeliveryCreate',
  components: {
    Loading
  },
  props: {
    listButton: {
      default: true,
      type: Boolean
    },
    taskRequest: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      model: null,
      createResult: null,
      //view only
      dialog: false,
      loading: false,
      saving: false,
      valid: false,
      menuExpectedStart: false,
      menuExpectedEnd: false,
      deliveryInput: null,
      locationInput: null,
      departmentInput: null,
      subjectInput: null,
      followerPersonsInput: null,
      rules: {
        required: [
          value => !!value || 'Skal udfyldes'
        ]
      }
    };
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`delivery/create`);

        this.model = response.data;

        // Handle DateTime values
        this.model.data.expectedStart = null;
        this.model.data.expectedEnd = null;

        // Auto-select responsibles if only one option
        this.model.data.responsibles?.forEach(r => {
          if (r.selectablePersons.length === 1) {
            r.selectedPersonId = r.selectablePersons[0].value;
          }
        });

        // set values from taskRequest if defined
        if (this.taskRequest) {
          this.model.data.taskRequestId = this.taskRequest.id
          this.model.data.location = { value: this.taskRequest.locationId }
          this.model.data.departments = this.taskRequest.departments
          this.model.data.subjectAreas = this.taskRequest.subjectAreas
          this.model.data.title = this.taskRequest.title
        }
      } catch (error) {
        this.dialog = false;
        this.$notifier.showError({ message: this.$t('errors.createDelivery') });
        this.Sentry.withScope((scope) => {
          scope.setLevel('critical');
          scope.setTransactionName('DeliveryCreate');
          this.Sentry.captureException(error);
        });
      } finally {
        this.createResult = null;
        this.loading = false;
      }
    },
    async create() {
      this.saving = true;

      try {
        const { data } = await this.$AuthService.api.post(`/Delivery/Create`, this.model.data);

        if (!data.success) throw new Error(data.errors[0] ?? 'An error occurred creating delivery')

        this.createResult = data;
        this.$notifier.showSuccess({ message: 'Leverance oprettet' });
        this.$emit('saved');
        this.dialog = false;
      } catch (error) {
        this.Sentry.withScope((scope) => {
          scope.setLevel('critical');
          scope.setTransactionName('DeliveryCreate');
          this.Sentry.captureException(error);
        });
        this.$notifier.showError({ message: this.$t('errors.createDelivery') });
      } finally {
        this.saving = false;
      }
    },
    cancel() {
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>