<template>
  <v-card>
    <v-card-title>
      <v-skeleton-loader type="avatar" />
      <v-skeleton-loader type="card-heading" width="60%" />

      <v-spacer></v-spacer>

      <div
        class="d-flex"
        style="gap: 1rem;"
      >
        <v-skeleton-loader type="avatar" />
        <v-skeleton-loader type="avatar" />
        <v-skeleton-loader type="avatar" />
      </div>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-skeleton-loader type="table-heading@3" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CaseCardSkeletonLoader'
}
</script>