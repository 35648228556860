<template>
  <v-container
    fluid
    v-scroll="loadMore"
  >
    <div>
      <v-row>
        <v-col>
          <v-toolbar
            color="transparent"
            flat
          >
            <v-card-title
              class="d-none d-sm-block pl-0"
            >
              {{ $t('deliveries.list.title') }}
            </v-card-title>

            <v-spacer />

            <v-text-field
              v-model="model.query.textSearch"
              append-inner-icon="mdi-magnify"
              :label="`Søg blandt leverancer`"
              placeholder="Skriv for at søge"
              :disabled="loading"
              autofocus
              hide-details
              solo
              @keyup.enter="onUserSearch"
            />

            <ListSearchButton
              class="ml-3"
              :loading="loading || searching"
              @click="onUserSearch"
            />

            <v-spacer />

            <DeliveryCreate
              @saved="onUserSearch"
            />
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row dense>
        <v-toolbar
          color="transparent"
          dense
          flat
        >
          <v-spacer />
          <div>
            <v-select
              v-model="model.query.sorting"
              :items="model.metadata.sortings"
              prepend-icon="mdi-sort"
              dense
              single-line
              @input="onUserSearch"
            />
          </div>
        </v-toolbar>
      </v-row>

      <v-row
        align="start"
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <ListFilters
            :loading="loading || searching"
            @click:reset="resetFilters"
            @click:search="onUserSearch"
          >
            <v-autocomplete
              v-model="model.query.locations"
              :items="model.metadata.locations"
              item-text="text"
              item-value="value"
              :label="$t('search.locations')"
              return-object
              :search-input.sync="locationInput"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.departments"
              :items="model.metadata.departments"
              item-text="text"
              item-value="value"
              :label="$t('search.departments')"
              return-object
              :search-input.sync="departmentInput"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.types"
              :items="model.metadata.types"
              item-text="text"
              item-value="value"
              :label="$t('search.types')"
              return-object
              :search-input.sync="typeInput"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.statuses"
              :items="model.metadata.statuses"
              item-text="text"
              item-value="value"
              :label="$t('search.statuses')"
              return-object
              :search-input.sync="statusInput"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.subjectAreas"
              :items="model.metadata.subjectAreas"
              item-text="text"
              item-value="value"
              :label="$t('search.subjectAreas')"
              return-object
              :search-input.sync="subjectInput"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-checkbox
              v-model="model.query.includeActive"
              :label="$t('search.includeActive')"
              hide-details
            />
            <v-checkbox
              v-model="model.query.includeClosed"
              :label="$t('search.includeClosed')"
              hide-details
            />
          </ListFilters>
        </v-col>

        <v-col>
          <div>
            <v-progress-linear
              :active="searching"
              indeterminate
            />

            <template
              v-if="!deliveries.length && loading"
            >
              <v-row
                v-for="n in 3"
                :key="n"
              >
                <v-col>
                  <CaseCardSkeletonLoader />
                </v-col>
              </v-row>
            </template>

            <v-row
              v-for="item in deliveries"
              :key="item.id"
            >
              <v-col>
                <DeliveryCard
                  :item="item"
                  :metadata="model.metadata"
                  :to="`delivery/${item.id}`"
                />
              </v-col>
            </v-row>

            <v-row v-if="!deliveries.length && !loading">
              <v-col>
                <v-card
                  flat
                  disabled
                >
                  <v-card-text class="d-flex justify-center py-10">
                    Ingen resultater
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row
              justify="center"
            >
              <v-fade-transition>
                <v-card
                  v-if="searching || noMoreItems"
                >
                  <v-card-text>
                    <template v-if="noMoreItems">
                      <div>
                        Ikke flere resultater
                      </div>
                    </template>
                    <template v-else>
                      <div>Indlæser flere</div>
                      <v-progress-linear indeterminate />
                    </template>
                  </v-card-text>
                </v-card>
              </v-fade-transition>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DeliveryCreate from '@/components/dashboard/DeliveryCreate'
import DeliveryCard from '@/components/delivery/DeliveryCard'
import Loading from '@/components/Loading.vue'
import ListFilters from '@/components/ListFilters.vue'
import ListSearchButton from '@/components/ListSearchButton.vue'
import CaseCardSkeletonLoader from '@/components/CaseCardSkeletonLoader.vue';

export default {
  
  name: 'DeliveryList',
  components: {
    DeliveryCreate,
    DeliveryCard,
    Loading,
    ListFilters,
    ListSearchButton,
    CaseCardSkeletonLoader
  },
  data() {
    return {
      locationInput: null,
      departmentInput: null,
      typeInput: null,
      statusInput: null,
      subjectInput: null,
      initialQuery: {},
      model: {
        data: {},
        metadata: {},
        query: {}
      },
      noMoreItems: false,
      loading: false,
      searching: false,
      searchTimeout: null
    }
  },
  computed: {
    ...mapGetters([ 'showOnlyRelevantContent' ]),
    deliveries() {
      return this.model.data.deliveries ?? [];
    }
  },
  watch: {
    showOnlyRelevantContent() {
      this.onUserSearch();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get('delivery/ListInitSearch');

        this.model = response.data;
        this.initialQuery = JSON.parse(JSON.stringify(response.data.query));
        this.model.query.includeActive = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async search () {
      this.searching = true;

      try {
        const response = await this.$AuthService.api.post('delivery/ListSearch', this.model.query);

        if (!this.model.query.offset) {
          this.model.data = response.data.data;
        }

        return response.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.searching = false;
      }
    },

    onUserSearch() {
      this.model.query.offset = 0;
      this.noMoreItems = false;

      this.$vuetify.goTo(0);

      this.search();
    },

    async loadMore() {
      if (this.noMoreItems) {
        return;
      }

      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 50;

      if (bottomOfWindow && !this.searching) {
        this.model.query.offset += this.model.query.take;
  
        const nextDeliveries = await this.search();

        if (!nextDeliveries?.deliveries?.length) {
          this.noMoreItems = true;
        } else {
          this.model.data.deliveries = this.model.data.deliveries.concat(nextDeliveries.deliveries);
        }
      }
    },

    resetFilters() {
      this.model.query = JSON.parse(JSON.stringify(this.initialQuery));
    }
  }
}
</script>